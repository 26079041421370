<template>
  <div class="ls-comfirm-dialog">
    <div class="wrap">
      <template v-if="isHint">
        <div class="ls-icons icon-hint-large"></div>
        <div class="title">提示</div>
        <div class="content">
          <p>您正在进行实名认证操作。</p>
          <p class="tip">
            今日剩余可操作次数：
            <span style="color: #f06060">{{ verifyNum }}</span>
          </p>
          <p>点击“确认”提交信息进行在线认证。</p>
        </div>
        <div class="btns">
          <div class="ls-btns-block" @click="onSubmit">确认</div>
          <div class="ls-btns-block spin" @click="onClose">取消</div>
        </div>
        <div class="ls-icons icon-close" @click="onClose"></div>
      </template>

      <template v-else-if="isLoading">
        <div class="ls-loading ls-icons icon-loading"></div>
        <div class="content">
          <p>实名认证信息正在验证中…</p>
        </div>
      </template>

      <template v-else-if="isSuccess || isFail">
        <div class="result" :class="statusType">
          <div class="ls-icons" :class="`icon-${statusType}`"></div>
          <div v-if="isSuccess" class="title">实名认证成功</div>
          <div v-if="isFail" class="title">实名认证未通过</div>
          <div v-if="isFail" class="content">
            <p>今日剩余可操作次数：{{ verifyNum - 1 }}</p>
          </div>
          <div class="btns">
            <div class="ls-btns-block" @click="toAccount">返回安全中心</div>
            <div v-if="isFail" class="ls-btns-block spin" @click="onClose">返回检查信息</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
/**
 * 验证弹窗提示
 */
import { submitRealNameInfo } from '@/api/interface/account'

export default {
  data() {
    return {
      isShow: false
    }
  },
  props: {
    realNameData: {
      type: Object,
      default: () => {}
    },
    verifyNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      statusType: 'hint'
    }
  },
  computed: {
    // 提示
    isHint() {
      return this.statusType == 'hint'
    },
    // 验证中
    isLoading() {
      return this.statusType == 'loading'
    },
    // 成功
    isSuccess() {
      return this.statusType == 'success'
    },
    // 失败
    isFail() {
      return this.statusType == 'fail'
    }
  },
  methods: {
    // 关闭弹窗
    onClose() {
      this.$emit('close')
    },
    // 返回安全中心
    toAccount() {
      this.$router.push('/account')
    },
    // 提交验证
    onSubmit() {
      if (this.isLoading) return
      this.statusType = 'loading'
      setTimeout(() => {
        this.fetchVerify()
      }, 200)
    },
    // 验证接口
    fetchVerify() {
      submitRealNameInfo(this.realNameData)
        .then(res => {
          this.statusType = res ? 'success' : 'fail'
        })
        .catch(error => {
          console.log(error)
          this.statusType = 'fail'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ls-comfirm-dialog {
  @extend %com-dialog-fixed;
  .wrap {
    @extend %v-h-center;
    width: 514px;
    height: 322px;
    padding: 32px 0 46px;
    background: #ffffff;
    text-align: center;
    .icon-hint-large {
      margin: 0 auto 10px;
    }
    .title {
      text-align: center;
      line-height: 25px;
      height: 25px;
      font-weight: bold;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 6px;
    }
    .content {
      text-align: center;
      margin-bottom: 20px;
      p {
        font-size: 14px;
        line-height: 20px;
        color: #3d3d3d;
        &.tip {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    .btns {
      display: flex;
      width: 258px;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }
    .result {
      .ls-icons {
        margin-top: 39px;
        margin-bottom: 15px;
      }
      .btns {
        margin-top: 20px;
      }
      &.success {
        .ls-btns-block {
          margin: auto;
        }
      }
    }
  }
  .icon-close {
    @extend %com-dialog-close;
  }

  .ls-loading {
    margin: 60px auto 33px;
  }
}
</style>
