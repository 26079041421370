<template>
  <section class="ls-container">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="auth-wrap main-section">
      <div class="hint-wrap">
        <span class="ls-icons icon-hint-warning"></span>
        <span class="ls-hint">温馨提示：请如实填写个人实名认证信息，并注意，您应为注册工作台账号时使用的手机号码持有人本人。</span>
      </div>

      <Form ref="paramsForm" class="form-box" :configs="formConfigs" :labelWidth="labelWidth" :paramsData="paramsData"></Form>

      <el-button type="primary" class="el-ls-btns" :loading="loading" @click="onSubmit">提交认证</el-button>
    </div>

    <ComfirmDialog v-if="dialogVisible" :realNameData="realNameData" :verifyNum="verifyNum" @close="onCloseConfirmDialog" />
  </section>
</template>

<script>
/**
 * 实名认证
 */
import ComfirmDialog from '@/components/common/ComfirmDialog.vue'
import BreadCrump from '@/components/common/BreadCrump.vue'
import Form from '@/components/common/Form'
import { mapGetters } from 'vuex'
import { verifyNumber } from '@/api/interface/account'
import identityIDCard from '@/utils/identityIDCard'

export default {
  watch: {},
  components: {
    BreadCrump,
    ComfirmDialog,
    Form
  },
  data() {
    const isIDCard = (rule, value, callback) => {
      const isPass = identityIDCard.validateIdCard(value)
      if (isPass) {
        callback()
      } else {
        callback(new Error('输入正确的证件编号'))
      }
    }

    return {
      breadcrumbList: [
        { menuName: '账号信息', routeName: 'Account' },
        { menuName: '认证中心-实名认证', curCss: 'cur' }
      ],
      comfirmItem: {
        isShow: false
      },
      labelWidth: 100,
      formConfigs: [
        {
          type: '',
          prop: 'phone',
          label: '手机号码',
          readOnly: true
        },
        {
          type: 'Input',
          prop: 'name',
          label: '姓名',
          maxlength: 20,
          placeholder: '请输入姓名',
          rules: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { pattern: /^[\u4e00-\u9fa5]+$/, message: '只能输入中文', trigger: 'blur' }
          ]
        },
        {
          type: 'Select',
          prop: 'idCardType',
          label: '证件类型',
          rules: { required: true, message: '请选择证件类型', trigger: 'change' },
          opction: [
            {
              label: '居民身份证',
              value: 0
            }
          ]
        },
        {
          type: 'Input',
          prop: 'idCard',
          label: '证件编号',
          maxlength: 20,
          placeholder: '请输入证件编号',
          rules: [
            { required: true, message: '请输入证件编号', trigger: 'blur' },
            {
              pattern:
                /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
              message: '输入正确的证件编号',
              trigger: 'blur'
            },
            { validator: isIDCard, trigger: 'blur' }
          ]
        }
      ],
      paramsData: {
        idCardType: 0,
        phone: ''
      },
      verifyNum: 0, // 剩余次数
      dialogVisible: false,
      realNameData: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    // 确认提交
    onSubmit() {
      this.$refs['paramsForm'].submitForm().then(res => {
        // delete res['phone']
        this.realNameData = res
        this.getVerifyNumber()
      })
    },
    // 关闭弹窗
    onCloseConfirmDialog() {
      this.dialogVisible = false
    },
    // 弹窗提示
    onConfrimDialog() {
      this.dialogVisible = true
    },
    // 获取验证剩余次数
    getVerifyNumber() {
      if (this.loading) return
      this.loading = true
      verifyNumber()
        .then(res => {
          this.verifyNum = res || 0
          if (res <= 0) {
            this.$message.info('您今日可提交认证次数已用完，请明日恢复后再试。')
          } else {
            this.onConfrimDialog()
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.paramsData = {
      idCardType: 0,
      phone: this.userInfo.phone
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import '@/scss/el/_form.scss';

.auth-wrap {
  width: 100%;
  margin-top: 16px;
  padding: 36px 79px 167px;
  background-color: #fff;
  .hint-wrap {
    span {
      vertical-align: middle;
    }
    .icon-hint-warning {
      margin-right: 4px;
    }
  }

  .form-box {
    margin-top: 70px;
    width: 400px;
  }

  .el-ls-btns {
    margin-top: 0.6rem;
    margin-left: 1.1rem;
  }
}
</style>
